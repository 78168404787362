.wrapper {
    padding: 0 20px 30px 20px;
    min-height: calc(100vh - 264px);
    background-color: #fff;
}

.help {
    width: 100%;
    padding: 14px 0;
    margin-bottom: 6px;
}

.list {
    margin-top: 0;
}
