.wrapper {
    display: flex;
    margin-bottom: 14px;
    align-items: center;
}

.title {
    margin-left: 10px;
}

.telco {
    font-size: 1.3rem;
    opacity: 0.7;
}

.icon {
    width: 44px;
    height: 44px;
    background-size: 100% 100%;
    background-image: url('https://cdn-gop.garenanow.com/gop/app/0000/100/067/icon.png');
}

@media (min-width: 0) and (max-width: 739px) {
    .wrapper {
        margin: 14px 0 10px;
    }
}
