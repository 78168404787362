.wrapper {
    padding: 0 20px;
    background-color: #fff;
    min-height: calc(100vh - 264px);
}

.help {
    width: 100%;
    padding: 14px 0;
    margin-bottom: 6px;
}

.list {
    margin-top: 0;
}
