.wrapper {
    max-width: 900px;
    padding: 0 30px;
    margin: auto;
    padding-bottom: 50px;
    min-height: calc(100vh - 240px);
}

.heading {
    display: flex;
    list-style: none;
    margin-top: 16px;

    li + li {
        margin-left: 18px;
    }

    a {
        color: #202325;
        display: block;
        padding-bottom: 6px;

        &.active {
            position: relative;
            color: #000;

            &::after {
                border-bottom: 1px solid #000;
                bottom: -1px;
                content: '';
                display: block;
                left: 0;
                position: absolute;
                right: 0;
            }
        }
    }
}

.list {
    margin: 20px 0 0;
}

.not {
    display: flex;
    align-items: center;
    justify-content: center;
}

.not-img {
    background-image: url('../../../assets/image/not-found.png');
    background-size: 100% 100%;
    flex: 0 0 auto;
    width: 86px;
    height: 73px;
    margin: 0 16px 0 0;
}

.not-mess {
    color: #d43831;
    font-size: 2rem;
}

.search {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.message-error {
    color: #d43831;
    font-size: 1.4rem;
    margin: 8px 0;
}

.input {
    width: 75%;
    color: #202325;
    background-color: transparent;
    padding: 11px 10px;
    border: 1px solid #c6c6c6;
    border-radius: 2px;
    text-align: left;
    display: inline-block;
    appearance: none;
    box-sizing: border-box;
    transition: border-color 0.3s, background-color 0.3s;

    &:focus {
        border-color: #93939d;
    }

    &::placeholder {
        opacity: 0.3;
        font-size: 1.4rem;
    }
}

@media (min-width: 0) and (max-width: 739px) {
    .wrapper {
        padding: 0 12px;
        margin: 0;
    }

    .not {
        flex-direction: column;
    }

    .not-img {
        width: 137px;
        height: 117px;
        margin-bottom: 18px;
    }

    .not-mess {
        font-size: 1.8rem;
    }

    .input {
        font-size: 1.6rem;
    }

    .message-error {
        font-size: 1.3rem;
    }
}
