.wrapper {
    max-width: 900px;
    padding: 0 30px;
    margin: auto;
    padding-bottom: 50px;
    width: 900px;
    min-height: calc(100vh - 260px);
}

.button {
    margin-bottom: 20px;
}

.content {
    margin-top: 10px;
}

.title {
    font-size: 20px;
    margin: 0;
    font-weight: 500;
}

@media (min-width: 0) and (max-width: 1023px) {
    .wrapper {
        width: auto;
        margin: 0;
        padding: 0 12px;
        padding-bottom: 30px;
    }
}
