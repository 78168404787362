.wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.item {
    width: 192px;
    height: 161px;
    cursor: pointer;
    position: relative;
    margin-bottom: 24px;
}

.image {
    width: 192px;
    height: 108px;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
    }
}

.border {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    border: 2px solid #d43831;

    &.active {
        opacity: 1;
    }
}

.discount {
    height: 28px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ef3d48;
    display: flex;
    color: #fff;
    text-align: center;
    text-shadow: hsl(0deg 0% 100% / 50%) 0 1px 0;
    line-height: 28px;
    padding: 0 6px;
    font-weight: 700;

    &::before {
        content: '';
        width: 0;
        height: 0;
        border-top: 28px solid #ef3d48;
        border-left: 20px solid transparent;
        margin: auto auto auto -26px;
    }
}

.name {
    line-height: 22px;
    margin: 5px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #211e1e;

    &.active {
        color: #ef3d48;
    }
}

.price {
    color: #ef3d48;
    display: inline-block;
}

.old-price {
    margin: 0 4px 0 6px;
    text-decoration: line-through;
    display: inline-block;
    vertical-align: middle;
    color: hsla(0, 0%, 46%, 0.5);
}

@media (min-width: 0) and (max-width: 739px) {
    .wrapper {
        flex-wrap: wrap;
        margin-left: -4px;
        margin-right: -4px;
    }

    .item {
        width: 47%;
        padding-left: 4px;
        padding-right: 4px;
    }

    .image {
        width: 100%;
        height: 96px;
    }
}

@media (min-width: 740px) and (max-width: 1023px) {
    .wrapper {
        justify-content: flex-start;
    }

    .item {
        margin-right: 16px;
    }
}
