.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.container {
    max-width: 600px;
    width: 100%;
    font-size: 1.4rem;
}

.error {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 20px 0;
}

.image {
    background-image: url('../../assets/image/not-found.png');
    background-size: 100% 100%;
    width: 86px;
    height: 73px;
    margin: 0 16px 0 0;
}

.message {
    color: #d43831;
    font-size: 2.4rem;
    line-height: 22px;
}

@media (min-width: 0) and (max-width: 739px) {
    .wrapper {
        padding: 0 30px;
        width: auto;
    }

    .container {
        width: 100%;
    }

    .error {
        text-align: center;
        display: block;
    }

    .image {
        margin: 20px auto;
    }

    .message {
        line-height: 1.2;
    }
}
