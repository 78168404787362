.wrapper {
    overflow: hidden;
    margin: 18px 0 0;
    padding: 0;
}

.item {
    float: left;
    width: 33.3%;
}

.box {
    text-align: center;
    display: block;
    box-sizing: border-box;
    border: 1px solid #ececec;
    padding: 29px 10px;
    color: #202325cc;

    &:hover {
        color: #202325;
        background-color: #f4f4f4;
        cursor: pointer;
    }
}

.image img {
    display: block;
    margin: 0 auto;
    width: 94px;
    height: 94px;
}

.name {
    line-height: 1.3;
    font-size: 1.4rem;
    font-weight: 500;
}

@media (min-width: 0) and (max-width: 739px) {
    .wrapper {
        display: flex;
        margin-top: 20px;
    }

    .item {
        width: 60%;
        margin: 0 auto;
    }

    .name {
        margin-bottom: 0;
    }
}
