.item {
    padding: 18px 0;
    border-bottom: 1px solid #ececec;

    &:first-child {
        border-top: 1px solid #ececec;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.value {
    display: flex;
    align-items: center;
}

.icon-diamond {
    width: 36px;
    height: 36px;

    img {
        width: 100%;
        height: 100%;
    }
}

.diamond {
    margin-left: 10px;
}

.diamond-title {
    font-size: 1.2rem;
    line-height: 1;
    opacity: 0.8;
}

.diamond-value {
    line-height: 1.5;
}

.face-value {
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    padding: 5px;
    opacity: 0.8;
}
