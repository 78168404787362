.overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 997;
    cursor: pointer;
}

.wrapper {
    flex-direction: column;
    align-items: center;
    inset: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    overflow: hidden;
    position: fixed;
    will-change: opacity, transform;
    z-index: 998;
}

.container {
    width: 406px;
    padding: 43px 17px 45px;
    margin: auto;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
}

.close {
    position: absolute;
    right: 17px;
    top: 17px;
    overflow: hidden;
    z-index: 100;
    cursor: pointer;
}

.close-icon {
    display: block;
    float: right;
    width: 16px;
    height: 16px;
    background-image: url('../../../assets/image/Icon-close.png');
    background-size: 100% 100%;
}

.heading {
    overflow: hidden;
    padding: 18px 0;
    border-bottom: 1px solid #f23737;
}

.heading-bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.icon {
    float: left;
    margin: 0 0 0 18px;
    width: 45px;
    height: 45px;
    display: block;
    border-radius: 50%;
}

.ig {
    float: left;
    margin: 12px 15px 13px 15px;
    font-size: 2rem;
    font-weight: 700;
    color: #ef363c;
}

.body {
    margin: 70px 0 0;
}

.from {
    width: 80%;
    margin: auto;
    position: relative;
}

.input {
    width: 100%;
    color: #202325;
    background-color: transparent;
    padding: 8px 10px;
    border: 1px solid #c6c6c6;
    border-radius: 2px;
    text-align: left;
    display: block;
    appearance: none;
    box-sizing: border-box;
    height: 34px;
    transition: border-color 0.3s, background-color 0.3s;

    &:focus {
        border-color: #93939d;
    }

    &::placeholder {
        opacity: 0.3;
        font-size: 1.4rem;
    }
}

.error {
    top: 30px;
    position: absolute;
    width: 100%;
}

.message {
    color: #d43831;
    font-size: 12px;
    margin: 10px 0;
}

.error-login {
    display: inline-block;
    margin-left: 4px;
    color: #959101;
    cursor: pointer;
}

.btn-login {
    margin: 30px 0 0;
}

.captcha {
    flex-direction: column;
    align-items: center;
    inset: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    overflow: hidden;
    position: fixed;
    will-change: opacity, transform;
    z-index: 999;
}

@media (min-width: 0) and (max-width: 739px) {
    .container {
        width: 86vw;
    }
}
