.wrapper {
    background-color: #fff;
    padding: 5px 0;
    border-bottom: 1px solid #ececec;
}

.container {
    max-width: 900px;
    padding: 0 30px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.help {
    display: flex;
    align-items: center;
}

.help-contact {
    cursor: pointer;
}

.logo {
    width: 111px;
    height: 31px;
    margin: 10px 0px 10px 0;
    background-size: 100% 100%;
    background-image: url('../../../assets/image/logo.png');
}

.client {
    font-weight: 500;
}

.profile {
    position: relative;
    display: inline-block;
    margin-top: auto;
}

.icon-mobile {
    display: none;
}

.user {
    height: 34px;
    line-height: 34px;
    display: flex;
    margin: 0 0 0 10px;
    border: 1px solid #d9d9d9;
    border-radius: 18px;
    font-size: 14px;
    align-items: center;
    color: #16181acc;
    cursor: pointer;
    overflow: hidden;

    &:hover {
        background: rgba(117, 50, 189, 0.3);
        border-color: #fdda25;
    }
}

.avatar {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    float: left;
    margin: 6px 6px 6px 8px;
    left: 0;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
    }
}

.nickname {
    opacity: 0.8;
    overflow: hidden;
    text-align: justify;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.login {
    padding: 0 11px;
    display: flex;
    user-select: none;
    transition: all 0.3s;

    &:hover {
        background: rgba(117, 50, 189, 0.3);
    }
}

.icon-bars {
    padding: 4px 8px;

    svg {
        font-size: 2rem;
    }
}

.icon-bars + .user {
    border: none;
}

.user-icon {
    border: none;

    &:hover {
        background: transparent;
        border-color: transparent;
    }
}

.icon-user {
    content: '';
    width: 24px;
    height: 24px;
    margin: auto 5px auto auto;
    background-size: 100% 100%;
    background-image: url('../../../assets/image/icon-user.png');
}

.tippy {
    position: absolute;
    z-index: 9999;
    width: 300px;
    background: #fff;
    box-shadow: 0 0 48px 0 rgb(25 24 24 / 10%);
    right: -60px;
    border-radius: 12px;
    overflow: hidden;
    margin: auto;
    padding: 10px 0;
}

.tippy-login {
    right: -18px;
}

.info {
    display: flex;
    max-width: 100%;
    align-items: center;
}

.info-avt {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    margin: 15px 10px 6px 20px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}

.info-nick {
    max-width: 170px;
    opacity: 0.8;
    overflow: hidden;
    font-size: 15px;
    line-height: 70px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 20px 0 0;
}

button.logout {
    width: 100%;
}

.logout {
    padding: 0 20px;
    display: block;
    max-width: 100%;
    background: #fff;
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 44px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    border: none;
    outline: none;

    text-align: left;

    &:hover {
        background: rgba(252, 9, 10, 0.08);
        color: #ef3d48;
    }
}

@media (min-width: 0) and (max-width: 739px) {
    .container {
        padding: 0 16px;
    }

    .logo {
        height: 28px;
    }

    .profile {
        display: none;
    }

    .icon-mobile {
        display: block;
        margin-left: 8px;
        padding: 10px 8px;
        margin-top: 4px;

        svg {
            font-size: 2.2rem;
            opacity: 0.6;
        }
    }
}
