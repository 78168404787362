.wrapper {
    min-width: 750px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.website {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.text {
    color: rgba(160, 174, 192, 1);
    font-size: 20px;
    letter-spacing: 0.6px;
}

.code {
    padding: 0 14px;
    border-right: 1px solid rgba(160, 174, 192, 1);
    line-height: 1;
}

.message {
    margin-left: 14px;
}

@media (min-width: 0) and (max-width: 739px) {
    .wrapper {
        min-width: 0;
    }
}
