.wrapper {
    padding: 30px 0;
    width: 85%;
    margin: 34px 32px;
    border-radius: 6px;
    height: calc(100vh - 418px);
}

.header {
    text-align: center;
    margin-bottom: 40px;

    img {
        border-radius: 50%;
        width: 40px;
    }
}

.title {
    display: inline-block;
    margin-left: 9.7px;
    font-size: 17.7px;
    font-weight: 700;
    vertical-align: top;
    height: 40px;
    line-height: 40px;
}

.form {
    width: 100%;
    margin: auto;
}

.form-control {
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: max-height 0.6s;
    max-height: 59px;
}

.input {
    flex: 1 1 auto;
    min-width: 0;
    color: #202325;
    background: transparent;
    padding: 17px 14px;
    border: none;
    border-bottom: 1px solid #c6c6c6;
    text-align: left;
    box-sizing: border-box;
    border-radius: 0;
    outline: 0;
    display: block;
    transition: border-color 0.3s, background-color 0.3s;
    font-size: 16px;
}

input::placeholder {
    opacity: 0.4;
}

.message-error {
    color: #ff6787;
    font-size: 1.4rem;
    margin: 8px 0 0 13px;
}

.btn {
    margin-top: 18px;
}
