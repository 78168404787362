.wrapper {
    margin-top: 14px;
    padding: 8px 14px;
    border-radius: 4px;
    border: 1px solid #d9534f;
    border-left: 6px solid #d9534f;
    background: rgba(255, 161, 158, 0.1);
}

@media (min-width: 0) and (max-width: 739px) {
    .wrapper {
        margin: 14px 8px 0;
    }
}
