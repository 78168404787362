.wrapper {
    background-color: rgb(0, 0, 0, 0.3);
    height: 100vh;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    top: 58px;
    width: 100vw;
    z-index: 1040;
    display: flex;
    transition: MobileMenu 0.5s ease;
}

.overlay {
    width: 26%;
}

.content {
    width: 72%;
    background-color: #fff;
    bottom: 0;
    right: 0;
    position: absolute;
    top: 0;
    animation: slideIn 0.4s ease;
}

.profile {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.avatar {
    background-image: url('https://cdn-gop.garenanow.com/gop/app/0000/100/067/icon.png');
    background-size: 100% 100%;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    margin-left: 20px;
}

.nickname {
    margin-left: 18px;
    font-size: 1.9rem;
    color: #000;
}

.history {
    margin-top: 4px;
    margin-left: 16px;
    font-size: 1.6rem;
    padding: 15px 0px;
    display: block;
    color: #000;
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
    text-align: left;
}

.login {
    margin: 20px;
    width: 86%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    background-color: #ebebeb;
    border: 1px solid #cacaca;

    span {
        font-size: 1.6rem;
        color: #000;
    }
}

.icon {
    background-image: url('../../../assets/image/icon-user.png');
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    margin-right: 4px;
}

@keyframes MobileMenu {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
