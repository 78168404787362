.wrapper {
    margin: 14px 0;
    overflow: hidden;
}

.list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2px;
    margin-right: -2px;
}

.item {
    margin-bottom: 4px;
    padding-left: 2px;
    padding-right: 2px;
    width: 16.22223%;
    min-height: 93px;
}

.arrow {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.icon {
    width: 22px;
    height: 10px;
    margin: 0 auto;
    background-image: url('../../assets/image/up.png');
    background-size: 100% 100%;
}

.card {
    width: 100%;
    border: 1px solid #ececec;
    position: relative;
    background-color: #fff;
    height: 100%;
    cursor: pointer;

    &::before {
        content: '';
        border: 3px solid #d4353b;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.3s;
    }

    img {
        width: 96%;
        height: 96%;
        display: block;
        margin: 3px;
    }
}

.image {
    width: 96%;
    height: 96%;
    margin: auto;
}

.card.disable {
    filter: grayscale(100%);
    cursor: not-allowed;
    pointer-events: none;
}

.card.active,
.card:hover::before,
.card.active::before {
    opacity: 1;
}

.card.disable::before {
    opacity: 0;
}

@media (min-width: 0) and (max-width: 739px) {
    .item {
        width: 50%;
    }
}

@media (min-width: 0px) and (max-width: 1024px) {
    .item {
        height: auto;
        min-height: 78px;
        width: 19%;
    }
}
