.wrapper {
    display: flex;
    align-items: center;
}

.title {
    flex: 0 0 auto;
    margin: 0 10px 0 0;
    font-size: 1.4rem;
    font-weight: 400;
}

.line {
    flex: 1 1 auto;
    content: '';
    height: 1px;
    background-color: #ececec;

    &:first-child {
        display: none;
    }
}

@media (min-width: 0) and (max-width: 739px) {
    .wrapper {
        padding: -0 50px;
    }

    .title {
        margin: 0 10px 0 10px;
        font-weight: 600;
    }

    .line {
        &:first-child {
            display: block;
        }
    }
}
