.wrapper {
    display: block;
    background: #d43831;
    color: #fff;
    border: none;
    width: 100%;
    padding: 12px 0;
    border-radius: 3px;
    cursor: pointer;
    transition: opacity 0.3s, background-color 0.3s;
    font-size: 1.4rem;
    text-align: center;

    &:hover {
        background-color: #ef4b44;
    }
}

.wrapper.display {
    width: 23%;
    display: inline-block;
    padding: 12px 0;
}

.wrapper.disable {
    opacity: 0.6;
    cursor: no-drop;
}

.back {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: -8px;
}

.icon-wrap {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 3px;
    background: #d43831;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.icon {
    width: 12px;
    height: 12px;
    background-size: 100% 100%;
    background-image: url('../../assets/image/btn-back.png');
}

@media (min-width: 0) and (max-width: 1023px) {
    .wrapper {
        margin-bottom: 14px;
        font-size: 1.6rem;
        padding: 14px 0;
    }

    .wrapper.display {
        margin-bottom: 0;
    }
}
