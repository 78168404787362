.wrapper {
    padding: 15px 4px;
    border-bottom: 1px solid #ececec;
    color: #202325;
    display: flex;
    line-height: 20px;
    justify-content: space-between;

    &:first-child {
        border-top: 1px solid #ececec;
    }

    &:hover {
        background-color: #f4f4f4;
    }
}

.product {
    text-align: left;

    img {
        display: inline-block;
        vertical-align: middle;
        height: 20px;
        margin: 0 6px 2px 0;
    }
}

.date {
    color: #20232599;
}

.value {
    text-align: right;
}

.result {
    color: #28a745;

    &.warning {
        color: #ffc107;
    }

    &.danger {
        color: #dc3545;
    }
}

.not {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.not-image {
    flex: 0 0 auto;
    width: 86px;
    height: 73px;
    margin-right: 16px;
    background-image: url('../../../../assets/image/not-found.png');
    background-size: 100% 100%;
}

.not-mess {
    color: #d43831;
    font-size: 2.4rem;
    line-height: 22px;
}

@media (min-width: 0) and (max-width: 739px) {
    .not-image {
        width: 50px;
        height: 43px;
        margin-right: 12px;
    }

    .not-mess {
        font-size: 1.6rem;
    }
}
