.wrapper {
    background-color: #fff;
    border-top: 1px solid #ececec;
    padding: 20px 0;
    overflow: hidden;
}

.container {
    max-width: 900px;
    padding: 0 30px;
    margin: auto;
}

.content {
    font-size: 1.4rem;
    text-align: center;
    color: #20232566;
    line-height: 26px;
    white-space: pre-wrap;
    margin-bottom: 10px;
}

.list {
    display: flex;
    align-items: center;
    justify-content: center;
}

.item {
    margin: 0 10px;
    position: relative;
    color: #20232566;

    &::after {
        content: '';
        position: absolute;
        right: -10px;
        top: 5px;
        bottom: 5px;
        width: 1px;
        background-color: currentColor;
    }

    &:last-child::after {
        display: none;
    }

    a:hover {
        color: #202325cc;
    }
}

@media (min-width: 0) and (max-width: 739px) {
    .container {
        padding: 0;
    }

    .content {
        padding: 0 28px;
        font-size: 1.3rem;
    }

    .item {
        margin: 0 7px;

        &::after {
            display: none;
        }

        a {
            font-size: 1.4rem;
        }
    }
}
