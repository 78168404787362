.wrapper {
    max-width: 900px;
    padding: 0 30px;
    margin: auto;
    padding-bottom: 50px;
    min-height: calc(100vh - 260px);
}

.header {
    display: flex;
    align-items: center;
}

.title {
    flex: 0 0 auto;
    margin: 0 10px 0 0;
}

.line-title {
    flex: 1 1 auto;
    content: '';
    height: 1px;
    background-color: #ececec;
}

.content {
    margin-top: 10px;

    a {
        color: #5e7fb2;
    }
}

.question {
    color: rgb(83, 89, 96);
    font-size: 1.6rem;
    line-height: 22px;
    padding-bottom: 8px;
}

.reply {
    color: #90959d;
    line-height: 24px;
    font-size: 1.6rem;
}

.line {
    padding: 10px 0;
}

@media (min-width: 0) and (max-width: 1023px) {
    .wrapper {
        padding: 0 12px;
        margin: 0;
        margin-bottom: 30px;
    }
}
