.wrapper {
    padding-bottom: 50px;
    min-height: calc(100vh - 260px);
}

.container {
    max-width: 900px;
    padding: 0 30px;
    margin: auto;
}

marquee {
    margin-bottom: 10px;
}

.marquee {
    margin-right: 40px;

    b {
        color: #d43831;
    }
}

.border {
    display: flex;
    align-items: center;

    img {
        height: 24px;
        margin-right: 12px;
    }
}

.game {
    margin-right: 10px;
    font-weight: 500;
    font-size: 1.4rem;
}

.reload {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 24px;
    padding: 0 6px;
    margin-left: 10px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 2px;
    border: 1px solid #acacae;
    order: 4;
}

.icon-reload {
    background-image: url('../../assets/image/reload.png');
    background-size: 100% 100%;
    width: 12px;
    height: 12px;
    margin-right: 5px;
}

.text-reload {
    cursor: pointer;
}

.login {
    flex: 0 0 auto;
    margin-right: 10px;
}

.line {
    content: '';
    height: 1px;
    background-color: #d3d3d3;
    flex: auto 1 1;
    order: 3;

    &:first-child {
        display: none;
    }
}

@media (min-width: 0) and (max-width: 739px) {
    .wrapper {
        min-height: calc(100vh - 264px);
        background-color: #fff;
        padding-bottom: 0;
    }

    .slide {
        display: none;
    }

    .container {
        padding: 0;
    }

    .border {
        padding: 0 12px;
        justify-content: space-between;

        img {
            margin: 0;
        }
    }

    .game {
        margin-right: 60px;
    }

    .login {
        margin-left: 10px;
    }

    .line {
        display: none;

        &.active {
            display: block;
        }

        &.active {
            display: block;

            &:first-child {
                display: block;
                order: unset;
            }
        }
    }
}
