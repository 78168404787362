.wrapper {
    width: 100%;
    margin-top: 20px;
}

.card {
    display: flex;
    align-items: center;

    span {
        width: 24px;
        height: 24px;
        background-image: url('../../assets/image/icon-card.png');
        background-size: 100% 100%;
        margin-right: 12px;
    }
}

.name {
    flex: 0 0 auto;
    margin: 0 10px 0 0;
}

.pay {
    display: flex;
}

.left {
    width: 415px;
}

.title {
    font-size: 14px;
    margin-top: 20px;
    color: #211e1e66;
}

.header {
    display: flex;
    align-items: center;
    padding: 7px 0;
    margin-top: 8px;
    border-bottom: 1px solid #ececec;
}

.price {
    width: 150px;

    padding-left: 10%;
    flex: 0 0 auto;
    color: #d43831;
    margin: 0;
}

.diamond {
    flex: 1 1 auto;
    padding-left: 18px;
    color: #d43831;
    margin: 0;
}

.list {
    padding: 0;
    margin: 0;

    .item {
        cursor: pointer;
        padding: 10px 0;
        border-bottom: 1px solid #ececec;
        border-top: 0;
        display: block;
        position: relative;

        &.active {
            border: 1px solid #d43831;

            .check::before {
                background-image: url('../../assets/image/check-active.png');
            }
        }

        .check::before {
            content: '';
            position: absolute;
            left: 14px;
            top: 11px;
            width: 14px;
            height: 14px;
            background-image: url('../../assets/image/icon-check.png');
            background-size: 100% 100%;
        }
    }
}

.value {
    position: relative;
    padding-left: 40px;
    width: 150px;
    display: inline-block;

    span {
        color: #333;
    }

    &::before {
        content: '';
        position: absolute;
        top: 1px;
        bottom: 1px;
        right: 0;
        width: 1px;
        background-color: #ececec;
    }
}

.value-received {
    padding: 0 0 0 5%;
    display: inline-block;
}

.line {
    flex: 1 1 auto;
    min-width: 40px;
    position: relative;

    &::before {
        position: absolute;
        content: '';
        height: 100%;
        width: 1px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        background-color: #ececec;
    }
}

.no-card {
    width: 393px;
    flex: 0 1 auto;

    .no-result {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

@media (min-width: 740px) and (max-width: 1023px) {
    .pay {
        display: block;
    }

    .left {
        width: 100%;
    }

    .line {
        display: none;
    }

    .list {
        .item {
            .check::before {
                left: 40px;
            }
        }
    }

    .header {
        .price {
            width: 30%;
        }

        .diamond {
            padding: 0 5% 0 10.4%;
        }
    }

    .value {
        width: 36%;
        padding-left: 70px;
    }

    .value-received {
        padding: 0 0 0 8%;
    }
}
