.wrapper {
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
        height: 100%;
    }

    &.height {
        height: 100vh;
    }

    &.vh50 {
        height: 50vh;
    }
}

.loading {
    background-size: 100% 100%;
    margin: 48px auto;
    width: 48px;
    height: 48px;
    background-image: url('../../assets/image/loading.png');
    animation: Loading 1s linear infinite;
}

@keyframes Loading {
    100% {
        transform: rotate(1turn);
    }
}
