.wrapper {
    display: none;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.3);
    inset: 0;
    position: fixed;
    z-index: 998;
    cursor: pointer;
}

.container {
    border-radius: 6px;
    inset: auto;
    left: 50%;
    max-width: 30vw;
    min-width: 400px;
    top: 44%;
    transform: translate(-50%, -50%);
    animation: Modal_fadeIn 0.2s ease;
    display: flex;
    overflow: hidden;
    position: fixed;
    will-change: opacity, transform;
    z-index: 999;
}

.content {
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    padding: 10px;
    background-color: #fff;
}

.image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

.close {
    margin-top: 20px;
    padding: 0 160px;
}

@keyframes Modal_fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (min-width: 0) and (max-width: 739px) {
    .wrapper {
        min-width: 90vw;
    }

    .content {
        padding: 10px 10px 0 10px;
    }

    .close {
        padding: 0 40px;
    }
}
