.wrapper {
    max-width: 900px;
    padding: 0 30px;
    margin: auto;
    padding-bottom: 50px;
    width: 900px;
}

.button {
    margin-bottom: 20px;
}

.content {
    margin-top: 10px;

    ol {
        counter-reset: item;
        list-style: none;
        padding: 0;

        li {
            display: table;
            margin: 15px 0;
            line-height: 22px;

            &::before {
                counter-increment: item;
                display: inline-block;
                width: 60px;
                content: counters(item, '.') '. ';
            }
        }
    }

    ol li ol li:before {
        width: 60px;
        display: table-cell;
    }
}

@media (min-width: 0) and (max-width: 1023px) {
    .wrapper {
        margin: 0;
        width: auto;
        padding: 0 12px;
        margin-bottom: 30px;
    }

    .content {
        ol li::before {
            width: 40px;
        }

        ol li ol li:before {
            width: 40px;
        }
    }
}
