.wrapper {
    margin-top: 20px;
    border-top: 1px solid #ececec;
    background-color: #fff;
}

.list {
    margin: 0;
}

.item {
    border-bottom: 1px solid #ececec;
    padding: 0 20px;

    &:last-child {
        border-bottom: 0;
    }

    a {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.item.disabled {
    filter: grayscale(100%);
    pointer-events: none;
}

.left {
    display: flex;
    align-items: center;
}

.name {
    color: #000;
    margin-left: 10px;
    font-size: 1.6rem;
}

.image {
    width: 40px;
    height: 40px;
    padding: 18px 0;

    img {
        width: 96%;
        height: 96%;
        object-fit: contain;
    }
}

.right {
    display: flex;
    align-items: center;

    svg {
        opacity: 0.6;
        font-size: 1.3rem;
    }
}

.hot {
    display: inline-block;
    margin-right: 10px;
    color: #ff4a00;
    font-size: 1rem;
    padding: 4px 5px;
    border-radius: 4px;
    border: 1px solid currentColor;
    text-align: center;
    line-height: 1;
    box-sizing: border-box;
}
