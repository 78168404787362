.wrapper {
    margin-top: 10px;
    border-top: 1px solid #ececec;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    color: #d43831;
    font-size: 2.2rem;
    margin: 10px 0;
}
