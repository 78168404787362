.wrapper {
    padding: 0 20px 30px 20px;
    min-height: calc(100vh - 264px);
}

@media (min-width: 0px) and (max-width: 1024px) {
    .wrapper {
        background-color: #fff;
    }
}
