.wrapper {
    margin: 18px 0 10px 0;
    min-height: 100px;

    img {
        width: 100%;
    }
}

@media (min-width: 0) and (max-width: 1023px) {
    .wrapper {
        background-color: #fff;
        min-height: 0;
        margin-bottom: 20px;

        img {
            height: 74px;
        }
    }
}
